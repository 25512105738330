import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/shared/seo"
import Emoji from "a11y-react-emoji"
import { Link, useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Img from "gatsby-image"

const NotFoundPage = () => {
  const [pageTitle, setPagetitle] = useState('404');
  const data = useStaticQuery(graphql`
    query {
      peepImage: file(relativePath: { eq: "peep-44.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)


  return (
    <Layout pageTitle={`Page not found`}>
      {pageTitle === "Steve" &&
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
      }
      <SEO title="404: Not found" />

      <section className={`grid-container c-banner`}>
        <div className={`grid-x grid-margin-x grid-margin-y align-justify c-banner__container`}>
          <div className={`cell medium-7`}>
            <div className={`c-banner__heading c-banner__heading--small`}>
              <h2>{pageTitle}</h2>
            </div>
            <div className={`c-banner__content`}>
              <p className={`u-text-medium`}>This page isn’t supposed to exist… but here it is. You did this and as far as we are concerned, you’re now responsible for it.</p>

              <p>
                <Link to={`/`} className={`c-button`}>
                  <Emoji symbol={`😒`} label={`Unamused`} className={`c-button__symbol`} />
                  I’m not ready for this
                </Link>
              </p>

              <p>
                <button className={`c-button`} onClick={() => setPagetitle("Steve")}>
                  <Emoji symbol={`😎`} label={`Smiling face with sunglasses`} className={`c-button__symbol`} />
                  I'll name it... Steve!
                </button>
              </p>
            </div>
          </div>
          <div className={`cell medium-4`}>
            <Img fluid={data.peepImage.childImageSharp.fluid} />
          </div>
        </div>
      </section>

    </Layout>
  )
}

export default NotFoundPage
